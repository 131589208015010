<template>
  <div class=" ">

    <div class="home w-100 d-flex flex-column">


      <div class="d-flex  mt-0 pt-5 landing-section alignt-items-end">
        <div class="container mt-5 mb-md-5 pt-5 px-0 d-flex align-items-end">


          <p class="text-left mt-5 pt-md-5 landing-text" v-fade-in="{ delay: 300, }">
            Get your need analysis done for 10 products including Retirement Corpus, Term Insurance, Health Insurance
            and Emergency Fund in less than a minute
          </p>
          <div style="display: flex; justify-content: flex-end">
            <img class="no_spam" src="/images/home/no_spam.svg" />
          </div>
        </div>
      </div>

      <div class="bg-white py-3 d-none d-md-block">
        <div class=" container ">
          <div class="row">
            <!-- <div class="d-flex col-3 position-relative">

                <h4 class="text-right text-muted ml-auto"
                style="font-size:2.8em;font-weight:200;"
                  v-fade-left="{delay:300,}"
                >
                  WHAT <br/>
                  KIND <br/>
                  OF  <br/>
                  USER <br/>
                  ARE <br/>
                  YOU
                </h4>

                <img src="/images/result/term.svg" alt=""
                  class="gray-icon" style="left:0;bottom:0"
                  v-fade-in="{delay:600}"
                >

              </div> -->

            <div v-for="(u, i) in orderedUserKinds" :key="u.title" v-fade-right="{ delay: 400 + (i + 1) * 200, }"
              class="col-4 d-flex flex-column ">
              <img :src="`/images/landing/human${u.key}.svg`" width="180px" class="mx-auto my-4" />

              <p class="lead mb-0" v-html="u.title">

              </p>
              <p class="text-dark mt-1 mb-2" style="font-weight:200;">
                {{ u.value }}
              </p>
              <p class="small text-secondary" v-html="u.desc">

              </p>

              <button :class="$options.buttonClass" class="mt-auto" @click.stop="handleAction(u.action)">
                {{ u.action }}
              </button>
              <button :class="$options.buttonClass" class="bg-transparent text-gray border-white px-2"
                @click.stop="handleAction(`SAMPLE REPORT ${u.key}`)">
                OR VIEW SAMPLE REPORT
              </button>
            </div>


          </div>
        </div>
      </div>

      <div class="bg-white py-5 d-md-none d-block">
        <div class=" container px-0">
          <div class="d-flex" @click="reOrder">
            <!-- <div class="d-flex "

              >

                <h4 class="text-right text-muted mr-2"
                style="font-size:2.2em;font-weight:200;max-width:3em;">
                  WHAT <br/>
                  KIND <br/>
                  OF  <br/>
                  PMW  <br/>
                  USER <br/>
                  ARE <br/>
                  YOU
                  <br/>
                  <span class="font-weight-500" style="font-weight:400;font-size:1.2em;">

                    <span v-for="c in ['pri','darkblue','blue']" :key="c"
                    :class="`text-${c} mx-0 px-0`"
                    style="width:3px;"
                    >
                    >
                    </span>
                  </span>
                </h4>
              </div> -->


            <transition-group class="flex-grow-1 d-flex pb-5 overflow-hidden position-relative">

              <div v-for="(u, i) in userKinds" :key="u.title" class=" d-flex flex-column  text-light mx-1 user-card"
                :class="u.key == 2 ? ' bg-darkblue' : (u.key == 1 ? ' bg-darkblue ' : ' bg-darkblue ')"
                :style="'font-size:0.8em;min-width:16em; ' + (i > 0 ? 'opacity:0.7' : '')">
                <img :src="`/images/landing/human${u.key}.svg`" width="140px" class="mx-auto my-4" />

                <p class="lead mb-1 text-white mx-2" style="font-weight:300;" v-html="u.title">

                </p>
                <p class=" text-white mb-1" style="font-weight:400;">
                  {{ u.value }}
                </p>
                <p class="small mb-1 mx-auto" v-html="u.desc" style="max-width:17em;color:#ffffff99;">

                </p>

                <button v-show="i == 0" :class="$options.buttonClass2 + ' ' + 'bg-green  text-pri'"
                  class="mt-auto py-2 mx-3 fade-in-up" style="transform:translateY(50%)"
                  @click.stop="handleAction(u.action)">
                  {{ u.action }}
                </button>
                <button :class="$options.buttonClass" class="bg-transparent text-gray border-darkblue px-2"
                  @click.stop="handleAction(`SAMPLE REPORT ${u.key}`)" style="transform:translateY(100%)">
                  OR VIEW SAMPLE REPORT
                </button>

              </div>
              <div class=" position-absolute d-flex" key="next-button" style="width:3em;height:3em;border-radius:50%;
                      right:4px;top:50%;background:#12121277;transform:translateY(-50%)">
                <i class="fas fa-arrow-right lead text-white m-auto"></i>
              </div>

            </transition-group>



          </div>
        </div>
      </div>


      <div class="bg-pri py-4">
        <div class="container">
          <div class="row row-cols-2 row-cols-md-4">

            <div v-for="(m, i) in metrics" :key="m.big" class="col metric"
              v-fade-in="{ delay: 200 + (i + 1) * 300, offset: 0.4 }">
              <p class="text-white my-1 metric-big">
                {{ m.big }}
              </p>
              <p class="text-light my-1 text-uppercase metric-small">
                {{ m.small }}
              </p>
            </div>
          </div>
        </div>
        <p></p>
        <!-- <div class="d-flex justify-content-center">
                <button :class="$options.buttonClass"
                 class="bg-transparent text-white border-white px-4"
                 @click.stop="handleAction('SAMPLE REPORT')">
                      VIEW SAMPLE REPORT
                   <img src="/images/shield.png" height="50px">
                 </button>
              </div> -->
      </div>



      <div class="bg-white py-5 d-none d-md-block" v-if="stories.length">
        <div class=" container ">
          <div class="row">
            <div class="d-flex flex-column align-items-end col-3 position-relative">

              <h4 class="text-right text-muted" style="font-size:2.8em;font-weight:200;">
                STORIES <br>
                FROM <br>
                OUR <br>
                BLOG
              </h4>
              <a href="https://medium.com/@protectmewell" target="_blank">
                <button :class="$options.buttonClass" class="px-4 p-2">
                  GO TO BLOG
                </button>
              </a>

              <img src="/images/result/retirement.svg" alt="" class="gray-icon" style="left:0;bottom:0"
                v-fade-in="{ delay: 600 }">
            </div>
            <!--
              <div v-if="loadingBlogs>0" class="col-9">


                  <div class="rounded mx-3 p-4 px-5 d-flex flex-column">
                    <div class="d-flex  mx-auto mt-3 align-items-center">

                        <img src="/images/shield.png" class="vloading mb-3" alt=""
                        style="width:60px;">
                    </div>

                    <p class="text-bluegray">
                        Fetching blogs...
                    </p>
                  </div>

              </div> -->


            <div v-for="(s) in stories" :key="s.title" class="col-3 d-flex flex-column cursor-pointer"
              @click="openLink(s.link)">
              <img :src="s.thumbnail" width="180px" class="mx-auto my-4" />

              <p class=" text-left mb-1 " style="font-weight:400;">
                {{ s.title }}
              </p>

              <p class="small text-secondary text-center">
                {{ s.user }}
                <span class="" style="font-weight:200;">
                  on {{ s.time }}
                </span>
              </p>


            </div>


          </div>
        </div>
      </div>


      <div class="bg-white py-5 d-md-none d-block" v-if="stories.length">
        <div class=" container ">

          <div class="d-flex flex-column align-items-end col-3 position-relative" style="max-width:16em;">

            <h4 class="text-right text-muted" style="font-size:2.8em;font-weight:200;">
              STORIES <br>
              FROM <br>
              OUR <br>
              BLOG
            </h4>
            <a href="https://medium.com/@protectmewell" target="_blank">
              <button :class="$options.buttonClass" class="px-4 p-2 mb-3">
                GO TO BLOG
              </button>
            </a>

            <img src="/images/result/retirement.svg" alt="" class="gray-icon" style="left:0;bottom:0"
              v-fade-in="{ delay: 600 }">
          </div>
          <!--
              <div v-if="loadingBlogs>0" class="col-9">


                  <div class="rounded mx-3 p-4 px-5 d-flex flex-column">
                    <div class="d-flex  mx-auto mt-3 align-items-center">

                        <img src="/images/shield.png" class="vloading mb-3" alt=""
                        style="width:60px;">
                    </div>

                    <p class="text-bluegray">
                        Fetching blogs...
                    </p>
                  </div>

              </div> -->

          <transition-group class="flex-grow-1 d-flex pb-5 overflow-hidden position-relative">


            <div v-for="(s, i) in stories" :key="s.title" class=" d-flex flex-column  text-light mx-1 user-card bg-pri"
              :style="'font-size:0.8em;min-width:12em; ' + (i > 0 ? 'opacity:0.7' : '')" @click="openLink(s.link)">

              <img :src="s.thumbnail" width="180px" class="mx-auto my-4" />

              <p class=" text-left px-4 text-center" style="font-weight:400;max-width:18em;">
                {{ s.title }}
              </p>

              <p class="small text-light text-center">
                <!-- {{s.user}} -->
                <span class="" style="font-weight:200;">
                  on {{ s.time }}
                </span>
              </p>
            </div>

            <div class=" position-absolute d-flex" key="next-button" style="width:3em;height:3em;border-radius:50%;
                    right:4px;top:50%;background:#12121277;transform:translateY(-50%)" @click="reorderStories">
              <i class="fas fa-arrow-right lead text-white m-auto"></i>
            </div>

          </transition-group>

        </div>
      </div>
      <div class="bg-white py-5">
        <div class="container">
          <div class="row">
            <div>

              <h4 class="text-left text-muted ml-auto col-12 mb-0" style="font-size:2.8em;font-weight:200;"
                v-fade-left="{ delay: 300, }">
                PARTNERS &amp; CLIENTS<br><br>
              </h4>
            </div>
          </div>
          <div class="row align-items-center ">
            <div class="col-md-3 d-flex p-1 px-4 flex-column" style="max-width:25%;min-width:150px"><img
                src="/images/partners/floatbot.png" alt="" style="object-fit: contain;" /></div>
            <div class="col-md-3 d-flex p-1 px-4 flex-column" style="max-width:25%;min-width:150px"><img
                src="/images/partners/eco-times.png" alt="" style="object-fit: contain;" /></div>
            <div class="col-md-3 d-flex p-1 px-4 flex-column" style="max-width:25%;min-width:150px"><img
                src="/images/partners/exegesis.png" alt="" style="object-fit: contain;" /></div>
            <div class="col-md-3 d-flex p-1 px-4 flex-column" style="max-width:25%;min-width:150px"><img
                src="/images/partners/Coverdesk.png" alt="" style="object-fit: contain;" /></div>
          </div>
        </div>
      </div>
      <!-- Testimonial -->
      <div class="bg-blue py-5">
        <div class="testimonials">
          <div>
            <h4 class="text-center text-light ml-auto col-12 mb-0" style="font-size:2.8em;font-weight:200;"
              v-fade-left="{ delay: 300, }">
              TESTIMONIALS <br><br>
            </h4>
          </div>

          <div class="testimonial-row d-flex ">
            <div class="col-md-4 style-8">

              <div class="card">
                <div class="layer"></div>

                <div class="content">
                  <blockquote>
                    <p>A few days ago, I was looking for a retirement calculator for #FIRE and came across
                      @ProtectMeWell
                      While it gives a ballpark figure, it was really good at projecting insurance needs. Yes - all of
                      us
                      are not adequately insured but do you know by how much?</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user1.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>Ravi Handa<br><a target="_blank"
                        href="https://twitter.com/ravihanda/status/1522808595569471488">@ravihanda</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 style-8">
              <div class="card">
                <div class="layer"></div>
                <div class="content">
                  <blockquote>
                    <p>Great thread on the importance of risk management when it comes to long term financial planning.
                      Sumit’s tool is free for a couple of days so do give it a spin👇🏽</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user2.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>Lavanya Mohan<br>
                      <a target="_blank" href="https://twitter.com/lavsmohan/status/1495055339380613123">@lavsmohan</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 style-8">
              <div class="card">
                <div class="layer"></div>
                <div class="content">
                  <blockquote>
                    <p>Absolutely endorse #protectmewell because it’s Insurer agnostic and customised for the user. I
                      got
                      my reports in a matter of minutes and being an Insurance professional, I am a hard one to impress
                      when it comes to Insurance & Insurtech solutions 😀😀</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user3.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>AnasuyaWithoutMalice<br><a target="_blank"
                        href="https://twitter.com/AnasuyaAnuG/status/1494931773452697603">@AnasuyaAnuG</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 style-8">
              <div class="card">
                <div class="layer"></div>
                <div class="content">
                  <blockquote>
                    <p>@RamaniSumit, it’s by far the most comprehensive report I have received & that too without the
                      hassle of sharing any email ID or mobile. This is the first mandatory step towards financial
                      planning, I wish I had something similar 15 years ago</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user4.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>Jabberwocky<br><a target="_blank"
                        href="https://twitter.com/jabberwocky8525/status/1494886458338930688">@jabberwocky8525</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 style-8">
              <div class="card">
                <div class="layer"></div>
                <div class="content">
                  <blockquote>
                    <p>Protectmewell gives wonderful insight about the insurance products required for each individual.
                      This is game changer!</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user5.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>Rohit Chandak<br><a target="_blank"
                        href="https://twitter.com/RohitChandak11/status/1493614776848891904">@RohitChandak11</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 style-8">
              <div class="card">
                <div class="layer"></div>
                <div class="content">
                  <blockquote>
                    <p>Awesome initiative @RamaniSumit @ProtectMeWell this will not just help spread financial awareness
                      by also benefit end consumer</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user6.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>kumbh Karan<br><a target="_blank"
                        href="https://twitter.com/Udan_khatola007/status/1493625789203374084">@Udan_khatola007</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 style-8">
              <div class="card">
                <div class="layer"></div>
                <div class="content">
                  <blockquote>
                    <p>Just checked it .Good one :) Wishing you the best.</p>
                  </blockquote>
                  <div class="image">
                    <img src="/images/users/user7.jpg" style="width:100px">
                  </div>
                  <div class="details">
                    <p>Vidya<br><a target="_blank"
                        href="https://twitter.com/VidyaG88/status/1495022176881491969">@VidyaG88</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

 


    <div class="bg-darkgreen py-5">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 position-relative">
            <img src="/images/result/health.svg" alt="" class="gray-icon" style="left:0;bottom:0"
              v-fade-in="{ delay: 600 }">
            <div class="row">
              <p style="font-size:2.8em;font-weight:200" class="text-white text-right col-6 mb-0 "
                v-fade-left="{ delay: 300, }">
                HOW <br>IT <br>WORKS



              </p>
              <div class="col-6 text-left" v-fade-above="{ delay: 100, }">
                <p class="text-uppercase text-white">The mechanism</p>
                <p class="small text-light">
                  To help you identify the accurate insurance needs,
                  a group of actuaries has devised the protection-focussed
                  web-based tool, ProtectMeWell™. This is a non-intrusive,
                  swift, scientific and unbiased tool. The underlying algorithm
                  is comprehensive, but the tool is very intuitive and easy
                  to use. The algorithm is based on factors like household
                  income, household expenses, assets, and liabilities.
                </p>

                <router-link to="how-it-works">
                  <button :class="$options.buttonClass" class="bg-transparent text-white border-white px-4">
                    KNOW MORE
                  </button>
                </router-link>

              </div>


            </div>


          </div>
          <div class="col-12 col-md-6" v-fade-right="{ delay: 600, }">
            <img src="/images/how-it-works/iceberg.svg" alt="iceberg" style="width:100%;">

            <img src="/images/result/illness.svg" alt="" class="gray-icon" style="right:0;top:0"
              v-fade-in="{ delay: 600 }">
          </div>


        </div>
      </div>

    </div>

    <Modal :show="takeEmail" @close="takeEmail = 0">

      <div class="bg-white rounded shadow my-auto">

        <button class="btn ml-auto float-right" @click="takeEmail = 0">
          <i class="fas fa-times text-muted"></i>
        </button>

        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="subscribeUser" action="">


            <div class="px-4 mt-5 mb-3  flex-grow-1">
              <ValidationProvider rules="email|required" v-slot="{ errors }">
                <input type="text" class="form-text-input bg-transparent mb-2 " v-model="fdata.email"
                  placeholder="Email Id for the introductory offer!" style="min-width:260px;">
                <p class="small mb-2 text-warning">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <button type="submit" class="rounded border-0   p-3
                      font-weight-bold  w-100 mt-auto "
              :class="(invalid ? 'disabled bg-gray text-darkgray' : ' bg-green text-pri shadow-sm')"
              :style="{ pointerEvents: invalid ? 'none' : '' }">
              REGISTER
              <i class="fas fa-circle-notch rotating ml-2" v-show="takeEmail == 2"></i>
            </button>
          </form>
        </ValidationObserver>


      </div>

    </Modal>



    <div class=" py-3 text-left bg-pri">
      <div class="container">

        <p class="text-light small" v-fade-below="{ delay: 200, }">
          <span class="d-inline font-weight-bold text-white mr-1">
            Disclaimer:
          </span>
          {{ disc }}
        </p>
      </div>
    </div>
    <Footer class="  pt-0  d-sm-block text-darkblue" :showLine="false" />



  </div>




</template>

<script type='text/javascript'>(function () { var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0]; s1.async = true; s1.src = 'https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75'; s1.charset = 'UTF-8'; s1.setAttribute('crossorigin', '*'); s0.parentNode.insertBefore(s1, s0); })(); </script>

<script>
// @ is an alias to /src
import texts from '../texts'
import Footer from '../components/special/Footer'
import Modal from '../components/common/Modal'
import {ValidationProvider,ValidationObserver} from 'vee-validate'
import qs from 'querystring'

export default {
  name: 'Home',
  buttonClass:' special-font bg-white text-darkblue border-pri font-weight-500 p-1 ',
  buttonClass2:' text-pri border-0 px-3 rounded font-weight-500 p-1 ',
  components: {
    Footer,
    Modal,
    ValidationObserver,
    ValidationProvider,
  },
  data(){
    return {
      fdata:{mother:{dependent:false,age:56}},
      showDrawer:false,
      activeLine:0,
      counter:0,
      handle:null,
      showButton:false,
      inc: 1,
      reverseSpeed: 2,
      tickTime:40,
      waitTime:0.9,
      lines:texts.home.lines,
      monies: texts.home.testimonies,
      disc:texts.home.disc,

      takeEmail:0,
      loadingBlogs:false,

      userKinds:[
        {title:'Do It Yourself',value:'',
          desc: texts.home.plans.yourself,
          action:'GET STARTED',
          key:1,
        },
        {title:'Insurance Agent/</br>Financial Advisor',value:'',
          desc:texts.home.plans.others,
          action:'SIGN UP',
          key:2,
        },
        {title:'Institutional Insurance Sellers',value:'',
          desc:texts.home.plans.many_others,
          action:'CONTACT US',
          key:3,
        }

      ],
      metrics:[
        {big:'20',small:'questions'},
        {big:'15',small:'clicks'},
        {big:'10',small:'products'},

        {big:'ONE',small:'report'},

      ],
      // stories:[
      //   {
      //     title:'Financial independence is changing a whole generation of young enterprenuers',
      //     user:'Anirudh Ravindra',
      //     time:'13 Aug 2020',
      //     image:'/images/landing/human1.svg',
      //   },
      //   {
      //     title:'Suitable for an insurance or financial agent, broker, or financial ',
      //     user:'Anirudh Ravindra',
      //     time:'13 Aug 2020',
      //     image:'/images/landing/human2.svg',
      //   },
      //   {
      //     title:'Recommended for users with usage more than 1000 reports per month.',
      //     user:'Anirudh Ravindra',
      //     time:'13 Aug 2020',
      //     image:'/images/landing/human3.svg',
      //   },
      // ],

    }
  },
  computed:{
    stories(){
      return this.$store.state.stories;
    },
    orderedUserKinds(){
      let uk = [...this.userKinds]
      return uk.sort((a,b) => a.key-b.key)
    },
    // sentence(){
    //   const str = this.lines[this.activeLine];
    //   let finalStr =  str.slice(0,Math.max(this.counter,0))

    //   return finalStr
    // }
  },
  mounted(){

    // this.handle = setInterval(this.incrementor, this.tickTime);

    // const img = new Image()
    // img.src = this.$refs.image.src.replace('_low','');

    // img.onload = () => {
    //   //
    //   this.$refs.image.src = img.src
    // }
    if (window.location.href.indexOf('giftCard') > -1 || window.location.href.indexOf('giftcard') > -1) {
        this.$store.commit('setGiftCardPopup', true)
    }
  },
  methods:{
    openLink(link){
      window.open(link)
    },
    async subscribeUser(){
      try {
        this.takeEmail = 2
        // if(e){e.preventDefault();}
        const config = {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY':'public-ifa-access'
            }
        }
        const data = qs.stringify({email:this.fdata.email})

        await this.$axios.post(this.$baseUrl+'ifa/subscribe/',data,config);
        // this.$warning('Feature not implemented yet')
        this.$success('Subscribed')

        this.takeEmail = 0
      } catch (err) {
        const res = err.response && err.response.data
        if(res && res.data){
          this.$error(res.data)
        }else{
          this.$error(err)
        }
        this.takeEmail = 1
      }

    },
    reOrder(){
        this.userKinds.push(this.userKinds.shift())
    },
    reorderStories(){
      const stories = this.stories
      stories.push(stories.shift())
        // thi s.$store.commit('setStories',stories)
    },
    goToRoute(name){
        if(this.$route.name != name){
            this.$router.push({name});
        }
      },
    handleAction(action){
      if(action == 'GET STARTED'){
        this.goToRoute('form')
      }
      else if (action == 'CONTACT US'){
        const email = 'sales@protectmewell.com'
        const subject = 'Many others plan'
        const body = ''
        location.href = "mailto:"+email+'?subject='+subject+'&body='+body;

      }
      else if (action == 'SIGN UP'){
        this.$store.commit('setShowSignIn',2)
        this.$root.$emit('onRegister')
      }else if (action == 'SAMPLE REPORT 1'){
         window.open(window.location.protocol + "//" + window.location.host+"/images/Jane_Doe_protectmewell_reco_retail_flow.pdf", "_blank");
      }else if (action == 'SAMPLE REPORT 2'){
         window.open(window.location.protocol + "//" + window.location.host+"/images/pmw_sample_individual.pdf", "_blank");
      }else if (action == 'SAMPLE REPORT 3'){
         window.open(window.location.protocol + "//" + window.location.host+"/images/pmw_sample_industry.pdf", "_blank");
      }
    },
    incrementor(){
      const typeBack = false;

      this.counter += this.inc;
      const str = this.lines[this.activeLine];
      if(this.counter <= -40){
        this.counter = 1
        this.inc = 1;
        this.activeLine += 1;
      }
      const lastLine = (this.activeLine>=this.lines.length-1)
      const buffer =  lastLine?0:this.waitTime*(1000/this.tickTime)

      if(this.counter > str.length + buffer){

        if(lastLine){
          clearInterval(this.handle);
          this.showButton = true;
        }else{
          if(typeBack){
            this.inc = - this.reverseSpeed ;
          }else{
            this.counter = -50;
          }

        }

      }


    },
  }
}
</script>


<style >
@keyframes pulse-animation {
  from {
    color: #2d75c8
  }

  to {}
}

.pulsar {
  animation: pulse-animation 0.4s linear infinite alternate;
}

.bold-desc {
  font-weight: bold;
  color: #323232;
}

.gray-icon {
  position: absolute;
  transform: scale(1.5);
  filter: saturate(0) opacity(0.1);
}

.fade-in-up {
  animation: slide-fade-up-animation 0.6s;
  position: relative;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slide-fade-up-animation {
  0% {
    opacity: 0;
    transform: translateY(120%);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;

  }
}

.user-card {
  transition: all 0.6s;
}

.container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}



.landing-section {
  background-image: url('/images/landing/father.jpg');
  /* background: rgb(235, 237, 236); */
  background-position: right;
  background-attachment: scroll;
  background-size: cover;
  min-height: 22em;

}

.landing-text {
  max-width: 60%;
  color: white;
  max-width: 100%;
  font-size: 1.6em;
  font-weight: 300;
  padding: 16px 32px;
  margin: 0;
  background-color: #2F3676bb;
  background-blend-mode: multiply;
}

.metric>.metric-big {
  font-size: 3.6em;
  font-weight: 200;
}

.metric>.metric-small {
  font-size: 1.0em;
  font-weight: 200;
}

@media screen and (max-width: 576px) {
  .landing-text {
    color: white;
    max-width: 100%;
    font-size: 0.9em;
    font-weight: 300;
    padding: 16px 32px;
    margin: 0;
    background-color: #2F3676bb;
    background-blend-mode: multiply;

  }

  .gray-icon {
    display: none;
  }

  .metric>.metric-big {
    font-size: 2.2em;
    font-weight: 200;
  }

  .metric>.metric-small {
    font-size: 0.86em;
    font-weight: 200;
  }

  .bold-desc {

    color: white;
  }

  .pulsar {
    animation: pulse-animation 0.4s linear infinite alternate;
    color: #F6BE00;
  }
}

.round-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: aqua;
  color: darkslategray;
  border: none;
  font-size: 1.6em;

}

#top-bar {
  background-color: var(--blue);
}

#text-container {
  min-height: 15.0em;
  transition: all 0.4s;
  border-radius: 8px;
}

.intro-text {
  font-size: 1.6em;
}

.small-text {
  font-size: 0.68em;
  ;
}


@media screen and (max-height:650px) {

  /* .small-text{
      font-size: 0.8em;;
    }
    .typed-text{
      font-size: 0.9em;;
    } */
  #text-container {
    min-height: 0;
    font-size: 0.80em;
  }
}

@media screen and (min-height:800px) {
  #text-container {
    min-height: 18.0em;
  }
}


#hero-image {
  height: 100%;
  /* max-width: 420px; */

  /* box-shadow:  0 0 10px red inset ; */
}


@media screen and (max-width: 992px) {
  #hero-image {
    height: 30vh;
  }

  #text-container {
    background-color: white;
    ;
  }
}

.testimage {
  --tsize: 86px;
  width: var(--tsize);
  height: var(--tsize);
  border-radius: 50%;
  background-color: lightgray;
  border: 4px solid white;
  /* transform: translateY(45%);
    left:0;
    right:0;
    bottom:0; */
}

.h-90 {
  height: 85vh;
}

.test-container {
  bottom: 0%;

}


.tcolor-1 {
  color: var(--darkblue);
}

.tcolor-2 {
  color: var(--blue);
}

.tcolor-3 {
  color: var(--darkblue);
}

.tcolor-4 {
  color: var(--blue);
}

.tcolor-5 {
  color: var(--darkblue);
}

/* Testimonial css */

.testimonials {

  margin-bottom: 5%;
}

.testimonials .card {
  position: relative;
  width: 300px;
  margin: 0 auto;
  background: rgb(253, 250, 250);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 10px 40px rgba(0, 0, 0, .5);
  overflow: hidden; 
}





.testimonials .card .layer {
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(#03a9f4, #e91ee3);
  z-index: 1;
  transition: 0.5s; */
}

.testimonials .card:hover {
  top: 0;

}

.testimonials .card .details .layer {
  position: relative;
  z-index: 0;

}

testimonials .card .content p {
  font-size: 18px;
  line-height: 24px;
  color: #fff; 
}

.testimonials .card .content .image {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
}

testimonials .card .content .details h2 {
  font-size: 18px;
  color: #fff; 
}

testimonials .card .content .details h2 span {
  color: #03a9f4;
  font-size: 14px;
  transition: 0.5s;
}

testimonials .card:hover .content .details h2 span {
  color: #fff;
}

blockquote {
  border: none;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: -10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote p {
  font-size: 15px;
}

blockquote p:before {
  content: open-quote;
  font-weight: bold;
  font-size: 50px;
  color: #889c0b;
}

blockquote p:after {
  content: close-quote;
  font-weight: bold;
  font-size: 50px;
  color: #889c0b;

}

.testimonial-row {
  overflow-x: auto;
  white-space: wrap;
}
</style>