<template>
<div class="footer text-left "
    :style="footerStyle"
      :class="inverted?'inverted':''"
>
    <div class="text-center  py-1 py-2 py-md-4 dsk-transparent small footer-sec"
        :class="[showLine?'footer-line':'', !inverted ? 'container-fluid' : '']"

    >

        <!-- <h6 class="logo-font font-weight-bold mb-1 text-left
            font-italic
        "
        >

            CONTACT

        </h6> -->
        <div class="row fqueries">
            <div class="col-md-4 col-4 mb-0 footer-text text-left queries-data" style="padding: 0">
                <span class="">
                Have queries? Reach us at
                </span><br>

                <span class="d-flex mt-2">
                    <img src="/images/footer/Group 12.png" height="32px" />
                    <p style="margin-left:10px; margin-top:5px;word-break: break-all;font-size:90%;" id="email-data">
                    info@protectmewell.com
                    </p>
                </span>

            </div> 

            <div class="col-md-4 col-4 mt-2 mt-sm-0">
                 <span>
                    Let's Connect
                </span><br>
                <div class="d-flex justify-content-center pt-3">
                    <div v-for="l in links" :key="l.name"
                        class="cursor-pointer mx-1 hover-enlarge ">
                        <a :href="l.link" target="_blank" style="color:inherit;">
                            <!-- <p :class="'lead fab m-0 fa-'+l.name"></p> -->
                            <img :src="`/images/footer/${l.filename}.png`" height="32px" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-4 mb-0 address-txt">
            <div style="justify-content: center;display: contents;">
                <img src="/images/footer/Ellipse 54.png" height="32px" />
                <span class="ml-1">
                    Our Office
                </span><br>
            </div>
            <p class="small m-0">
                {{$options.texts.footer.address}}
            </p>
        </div>



        </div>

        <p class="col-12 justify-content-center align-items-center
                d-none d-sm-flex  mt-4 pt-4">
                Copyright 2020 © ProtectMeWell
            </p>




    </div>
</div>
</template>

<script>
import texts from '../../texts'
export default {
    texts,
    props:{
        inverted:{
            default:false
        },
        showLine:{
            default:false
        }
    },
    data(){
        return {
            links:[
                {name:'facebook',link:'https://www.facebook.com/protectmewell', filename:'Ellipse 53'},
                {name:'twitter',link:'https://twitter.com/ProtectMeWell', filename:'Ellipse 50'},
                {name:'linkedin',link:'https://www.linkedin.com/company/protectmewell', filename:'Group 12'},
                {name:'medium',link:'https://medium.com/@protectmewell', filename:'Group 15'},
            ]
        }
    },
    computed:{
        footerStyle(){
            const style = {}
            const page = this.$route.name
            const isHome = page == 'home'
            if(isHome){
                style.backgroundColor = 'black'
            }

            if (page != 'how-it-works'){
                style.color = 'white'
            }

            return style
        }
    }


}
</script>

<style>
    .dsk-transparent{
        background-color: transparent;
    }

    .small{
        font-size: 75%;
    }

   

@media screen and (max-width:540px) {
    .fqueries {  
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap:30px;   
    
 
  }

  .queries-data {
     margin-left: 30px;
  }

  #email-data {
    margin-right: -30px;
    
  }

}
    @media screen and (min-width: 576px){
        .footer.inverted p, .footer.inverted span{
            /* color: aliceblue; */
            color: var(--normalgray);
            /* opacity: 0.8; */
        }
    }

    @media screen and (max-width: 767px) {

        .dsk-transparent p, .dsk-transparent span{
           color: var(--normalgray);
           /* opacity: 0.8; */
        }
    }

    @media (max-width: 575px) {
        .footer-text{font-size: 0.8em;}
        .footer{background-color: white;}
        .footer-sec img {
            width: 20px;
            object-fit: scale-down;
            height: 25px;
        }
    }
    .footer-line{
        border-top: 1px solid var(--bluegray);
    }
    .address-txt {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 180%;
        text-align: center;
        letter-spacing: 0.01em;
        opacity: 0.8;
        padding-top: 10px !important;
        max-width: 300px;
    }



</style>