<template>
<div >
<div
    :class="
        (isHome?' always-fixed pr-1 '+shadowClass:' auto-fixed')+
        ((isForm && mobileMode)?' hidable-header ':'')+' '+(showBg)+' '
        "
    id="navigation-header"
>

    <div class="my-navbar   w-100  flex-row p-2 pt-3
    align-items-center d-flex  container
    justify-content-between2"
        :class="
        ((isForm)?'binverted    ':((isFaq||isHome)?'binverted':'whitened'))
        "
    >
        <img src="/images/ham-white.svg" alt="" class="mr-3   d-lg-none "
        :class="(isHome || isHowItWorks || isAfm ||(isForm) )?'ham-icon-dark':'ham-icon-light'"
        @click="displayDrawer"
        >
        <button
            class="btn btn-link p-0 mr-auto"
            @click="goToRoute('home')"
        >

        <img src="/images/logo.png" alt="" class="   logo  mr-2 " >
        </button>

      <!-- <div class=" text-uppercase logo-font d-md-none" >
          <h5 class="   mt-2 text-white">
            <span class="font-weight-bold ">
              Protect Me
            </span>
            <span class="font-weight-light text-italic ">
             Well
            </span>
          </h5>

      </div> -->

    <div class="d-flex align-items-center"

    >
        <product-dropdown  v-on="$listeners" @getStarted="goToRoute('form')"
            @showGiftCardPopUp="resetForm();showGiftCardPopUp = true"
            class="d-none d-sm-inline"
        />

        <div class="d-flex align-items-center">
            <span class=" d-none d-lg-inline ">
                <a class=" bg-transparent  logo-font desktop-nav-button ml-1 mx-2 pb-1"
                    v-for="b in navButtons" :key="b.name"
                    :class="($route.name == b.name)?' font-weight-bold  bordered   ':' border-0 '"

                    @click="goToRoute(b.name)"
                >
                    {{routeName(b)}}
                </a>
            </span>

            <product-dropdown  v-on="$listeners" @getStarted="goToRoute('form')"
                @showGiftCardPopUp="resetForm();showGiftCardPopUp = true"
                class="inline d-sm-none" :mobileMode="true" align="right" :hover="false"
            />

            <button v-if="$route.path.indexOf('credit') <= -1" class="border-0  font-weight-200
                special-font ml-sm-2  shadow-sm
                text-uppercase p-2 rounded logo-font px-2 btn-mb"
                :class="(inverted)?'text-pri bg-white font-weight-bold':' text-white bg-pri'"
            @click="goToRoute('form')"


                >
                Get Started
            </button>

        </div>

        <div v-if="user && $route.path.indexOf('credit') <= -1" class="d-flex flex-row-reverse flex-md-row">
            <button
                class="bg-darkgreen  text-white  w-border px-2 p-2 outline-0 rounded-complete ml-2"
                :class="'font-weight-bold     '+
                (inverted?'text-white w-border bg-darkgreen ':'text-darkblue   ')"
                @click="showCreditScreen = true;selectedPlan = ''"
            >
                <img src="/images/shield.png" alt="" style="width:22px;filter:brightness(0) invert(1);">
                <span class="m-0 p-0 logo-font mr-2 " style="font-size:0.9em">
                {{$store.state.user.credits > 0  ? $store.state.user.credits  : 0}}
                </span>
            </button>

            <Modal
                :show="showCreditScreen"
            >
                <div class=" position-relative d-flex flex-column rounded-extra "
                            style="min-width:20em;"
                >
                    <button class="position-absolute fas fa-times
                    text-white small p-3 ml-auto border-0 bg-transparent"
                        style="right:0;top:0;"
                        @click="showCreditScreen = false"
                    >

                    </button>

                    <div class="p-4 text-uppercase bg-pri text-white
                        logo-font font-weight-bold mb-3"
                    >
                        Get Usage Credits
                    </div>
                    <p style="padding: 10px;text-align:center">SELECT PLAN</p>

                    <div v-for="plan in plans" :key="plan.name"
                    class=" d-flex justify-content-between text-pri p-2 px-4 position-relative  overflow-hidden"
                    :style="{backgroundColor:(plan.color||'#F4C624')}"
                    @click="selectedPlan = plan;"
                    >
                        <p class="font-weight-bold text-white position-absolute logo-font p-0 m-0 d-block"
                         style="font-size:4.8em;opacity:0.3;top:0%;right:10%;"
                        >
                            {{plan.credits}}
                        </p>
                        <div class="text-left">
                            <p class="small m-0 text-capitalize">{{plan.name}}</p>
                            <p class="font-weight-bold">
                                {{plan.credits}} CREDITS
                            </p>
                        </div>
                        <div class="text-right">
                            <p class="small m-0" style="text-decoration:line-through">
                                ₹ {{plan.price}}
                            </p>
                            ₹ {{plan.special_price}}
                        </div>
                    </div>

                    <div class="bg-blue d-flex justify-content-between text-pri px-4 p-3 cursor-pointer"
                    style="background-color:#55B0DF" @click="resetForm();showShareCreditScreen = true;">
                        <p class="font-weight-bold m-0">
                            SHARE CREDITS
                        </p>
                    </div>
                    <div v-if="$store.state.user.credits > 0" class=" d-flex justify-content-between text-pri px-4 p-3 cursor-pointer"  @click="resetForm();showCreditLinkScreen = true;creditLinkData.generatedCreditLink = ''">
                        <p class="font-weight-bold m-0">
                            SHARE LINK
                        </p>
                    </div>
                    <!-- <div class="p-4">
                        <input type="text" placeholder="Discount code">
                    </div> -->
                    <div class="d-flex mt-auto">
                        <button class="text-pri bg-red logo-font
                          border-0 font-weight-bold text-right p-3 pr-5"
                          @click="showCreditScreen = false"
                          >
                            NOPE
                        </button>
                         <button class="text-pri bg-green logo-font flex-grow-1
                          border-0 font-weight-bold text-right p-3 pr-5" @click="addCredit()">
                            {{selectedPlan ? 'PAY ' +selectedPlan.special_price : 'ADD CREDITS' }}
                        </button>
                    </div>
                </div>

            </Modal>
            <Modal
                :show="showShareCreditScreen"
            >
                <div class=" position-relative d-flex flex-column rounded-extra "
                            style="min-width:20em;"
                >
                    <button class="position-absolute fas fa-times
                    text-white small p-3 ml-auto border-0 bg-transparent"
                        style="right:0;top:0;"
                        @click="showShareCreditScreen = false"
                    >

                    </button>



                    <div class="bg-blue d-flex justify-content-between text-pri px-4 p-3"
                    style="background-color:#55B0DF">
                        <p class="font-weight-bold m-0">
                            SHARE CREDITS
                        </p>
                    </div>
                    <div class="p-2">
                        <v-input
                            type="email"
                            label="Email"
                            v-model="shareData.sharedEmailOrMob"
                            class="form-text-input text-left"
                        />
                    </div>
                    <div class="p-2">
                        <v-input type="number"
                            label="Credits To Share"
                            v-model="shareData.sCredits"
                            class="mb-3 form-text-input text-left"
                        />
                    </div>
                    <p v-if="shareData.sCredits > 0 " style="font-size: 13px;">{{$store.state.user.credits - shareData.sCredits }} remaining</p>
                    <div class="d-flex mt-auto">
                        <button class="text-pri bg-red logo-font
                          border-0 font-weight-bold text-right p-3 pr-5"
                          @click="showShareCreditScreen = false"
                          >
                            BACK
                        </button>
                        <button v-if="!loading" class="text-pri bg-green logo-font flex-grow-1
                          border-0 font-weight-bold text-right p-3 pr-5" @click="shareCredits()">
                            SHARE CREDITS
                        </button>
                        <button v-else class="text-pri bg-green logo-font flex-grow-1
                          border-0 font-weight-bold text-right p-3 pr-5">
                            <span class="p-2 fas fa-circle-notch rotating flex-grow-1"></span>
                        </button>

                    </div>
                </div>

            </Modal>
            <Modal
                :show="showCreditLinkScreen"
            >
                <div class=" position-relative d-flex flex-column rounded-extra "
                            style="min-width:20em;"
                >
                    <button class="position-absolute fas fa-times
                    text-gray small p-3 ml-auto border-0 bg-transparent"
                        style="right:0;top:0;"
                        @click="showCreditLinkScreen = false"
                    >

                    </button>
                    <div class="p-2" style="margin-top: 30px;">
                        <v-input
                            type="number"
                            label="Enter the number of links to create"
                            v-model="creditLinkData.link_No"
                            class="form-text-input text-left"
                        />
                    </div>
                    <div class="p-2">
                        <v-input type="number"
                            label="Enter the number of credits for each link"
                            v-model="creditLinkData.link_Credits"
                            class="mb-3 form-text-input text-left"
                        />
                    </div>
                    <div v-if="creditLinkData.link_No > 0 &&  creditLinkData.link_Credits > 0">
                        <p  style="font-size: 15px;">Credits Deductible <span style="color: red;background: lightgrey;padding: 6px;border: 1px solid darkgray;margin-left: 5px;font-weight: bold;">{{creditLinkData.link_No * creditLinkData.link_Credits}}</span></p>
                        <p style="color: red;max-width: 300px;font-size: 14px;" v-if="creditLinkData.isCreditsAvailable && !creditLinkData.generatedCreditLink">You will have {{$store.state.user.credits - (creditLinkData.link_No * creditLinkData.link_Credits) }} credits remaining after the deduction</p>
                        <p style="color: red;max-width: 300px;font-size: 14px;" v-if="creditLinkData.isCreditsAvailable && creditLinkData.generatedCreditLink">You will have {{$store.state.user.credits }} credits remaining after the deduction</p>
                        <p style="color: red;max-width: 300px;font-size: 14px;" v-if="!creditLinkData.isCreditsAvailable">You don't have enough credits to make the purchase. You need at least {{ (creditLinkData.link_No * creditLinkData.link_Credits) }} credits</p>
                        <button v-if="!creditLinkData.isCreditsAvailable" style="margin: auto;margin-bottom: 20px;width: 150px;" class="bg-darkblue text-white logo-font
                          border-0 font-weight-bold text-center p-2"
                          @click="showCreditLinkScreen = false;showCreditScreen = true"
                          >
                            ADD CREDITS
                    </button>
                    <div v-if="!creditLinkData.generatedCreditLink">
                        <button v-if="creditLinkData.isCreditsAvailable && !loading" style="margin: auto;margin-bottom: 20px;margin-top:20px;color: black;width: 200px;" class="text-pri bg-green logo-font
                            border-0 font-weight-bold text-center p-2"
                            @click="creditLinkGenerate()"
                            >
                            {{ creditLinkData.link_No > 1 ? 'Create and email links* to me' : 'Create and share link'}}
                        </button>
                        <button v-else style="color: black;width: 200px;" class="logo-font flex-grow-1
                            border-0 font-weight-bold text-center p-2">
                                <span class="text-pri p-2 fas fa-circle-notch rotating flex-grow-1"></span>
                        </button>
                    </div>
                    <div v-if="creditLinkData.generatedCreditLink" style="max-width: 20em;overflow: hidden;margin:10px;">
                        <div style="background: rgb(204, 204, 204);padding: 5px;color: rgb(102, 102, 102);display:flex;cursor;pointer"
                        v-clipboard="creditLinkData.generatedCreditLink"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError">
                            <p style="width: 265px;overflow: hidden;margin: 0;">{{creditLinkData.generatedCreditLink}} </p>
                            <span style="cursor:pointer;width: 100px;"><i class="fa fa-copy" style="margin-left: 5px;margin-top: 3px;margin-right:3px;">
                            </i>Copy</span>
                            <!-- <span><i class="fa fa-share" style="margin-left: 5px;margin-top: 3px;cursor:pointer"></i></span> -->
                        </div>
                    </div>
                    <p style="width: 300px;font-size: 12px;margin-left: 5px;color: gray;margin-top:10px;">*Your credit will be refunded if any links not used within 1 day</p>
                    </div>
                </div>

            </Modal>
        </div>
         <!-- <div v-if="!user && $store.state.creditLinkCredits && $route.path.indexOf('credit') > -1">
         <button
                class="bg-darkgreen  text-white  w-border px-2 p-2 outline-0 rounded-complete ml-2  btn-mb shield-btn"
                :class="'font-weight-bold     '+
                (inverted?'text-white w-border bg-darkgreen ':'text-darkblue   ')">
                <img src="/images/shield.png" alt="" style="width:22px;filter:brightness(0) invert(1);">
                <span class="m-0 p-0 logo-font mr-2 " style="font-size:0.9em">
                {{$store.state.creditLinkCredits > 0  ? $store.state.creditLinkCredits  : 0}}
                </span>
            </button>
         </div>  -->
        <button v-if="!$store.state.user && $route.path.indexOf('credit') <= -1"
            class="desktop-specific bg-transparent special-font  ml-1 ml-2 p-1 px-3"
            :class="'font-weight-500  rounded   '+
            (inverted?'text-white w-border ':'text-pri p-border')"
            @click="openSignIn"
        >
        SIGN IN
        </button>
        <button v-else-if="$route.path.indexOf('credit') <= -1"
            class=" desktop-specific bg-transparent special-font  ml-1 ml-2 p-1 px-3"
            :class="'font-weight-500  rounded   '+
            (inverted?'text-white w-border ':'text-pri p-border')"
            @click="signOut"
        >
        LOG OUT
        </button>


    </div>



        <Modal
            :show="showSignIn"
            @close="()=>{}"
        >

            <div class="d-flex flex-column p-0" style="max-width:32em;">
                <div class="bg-pri d-flex" style="overflow: auto" >
                    <div class="d-flex   align-items-center
                     p-2 px-5
                    font-weight-bold flex-grow-1">
                        <div class="flex-grow-1 d-flex justify-content-center
                        cursor-pointer"
                        v-for="(t,n) in ['LOGIN','REGISTER']" :key="t">
                            <div
                            class="login-tab logo-font px-4   py-2"
                            :class="loginTab == n+1 ? 'active':''"
                            @click="showSignIn = n+1;loginTab === 1 ? sentOTP = false : sentOTP = false"
                            >
                                {{t}}
                            </div>
                        </div>

                    </div>
                        <button class="fas fa-times text-darkblue
                        lead bg-transparent border-0 p-4 outline-0"
                            @click="hideSignIn"
                        >

                        </button>
                </div>

                <ValidationObserver v-slot="{ handleSubmit }" >
                    <form @submit.prevent="handleSubmit(handleNext)" :key="formKey">
                <div class="p-5">
                            <div v-if="loginTab == 1">
                                <v-input
                                    label="Email"
                                    v-model="fdata.emailOrPhone"
                                    class="mb-3 form-text-input text-left"
                                />
                                <v-input type="password"
                                    v-if="sentOTP"
                                    label='Password/OTP'
                                    v-model="fdata.otp"
                                    class="form-text-input mb-4"
                                />


                            </div>

                            <div v-else>
                                    <v-input type="text" label='Name'
                                    class="mb-2 form-text-input" v-model="fdata.name"
                                    />
                                    <v-input type="email" label='Email'
                                    v-model="fdata.email"
                                    class="mb-2 form-text-input"
                                    />
                                    <v-input type="number"  label='Phone Number'
                                        v-model="fdata.mobile"
                                        class="mb-2 form-text-input"
                                    />
                                    <div class="text-left d-flex align-items-center mt-auto mb-3">
                                        <!-- <input class="form-checker" type="checkbox" value=""> -->

                                        <button
                                            class="  mr-2 rounded  border border-info outline-0"
                                            @click="fdata.agree = !fdata.agree"
                                            :class="fdata.agree?'bg-blue':'bg-white'"
                                        >
                                            <i class="fas fa-check text-white"></i>

                                        </button>

                                        <a class="ml-1 p-0 text-info cursor-pointer my-link" @click="showTheTerms()">
                                        Terms and Conditions
                                        </a>
                                    </div>
                                    <v-input type="password"
                                        label='OTP/Password'
                                        v-if="sentOTP"
                                        v-model="fdata.otp"
                                        class="form-text-input my-4"
                                    />

                            </div>

                <div class="g-recaptchaRE" v-show="showCaptcha"
                    id="g-recaptcha"
                ></div>

                <button class="btn btn-link text-right w-100 text-muted" @click="resendOTP" v-show="sentOTP">
                    <span v-if="sendingOTP" >
                        <span class="fas fa-circle-notch rotating"></span>
                        sending ...
                    </span>
                    <span v-else>
                        Resend OTP
                    </span>
                </button>

                </div>


                <div class="" >
                    <div class="d-flex justify-content-around
                    font-weight-bold">
                        <button class="text-pri bg-red logo-font
                           border-0 font-weight-bold p-3 px-5"
                           @click="showSignIn=false"
                           >
                            CANCEL
                        </button>
                        <button class="text-pri bg-green logo-font flex-grow-1
                          border-0 font-weight-bold text-right p-3 pr-5"
                          type="submit"
                          >
                            <span v-if="loading" class="fas fa-circle-notch rotating">
                            </span>
                            <span v-else>
                                {{sentOTP?(loginTab==1?'LOGIN':'SAVE AND NEXT'):'Send OTP'}}
                            </span>
                        </button>
                    </div>
                </div>

                        </form>
            </ValidationObserver>



            </div>

        </Modal>

    <Modal :show="showTerms" @close="showTerms=false"

    >
          <div class="d-flex rounded-extra flex-column "
           >
            <div class=" flex-grow-1 mx-2 d-flex flex-column  py-3 px-1">

                    <h5 class="text-left mt-2 mb-4 my-text">Terms and Conditions</h5>

                    <div class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-justify bg-light rounded">
                            <p v-html="texts.form.termsAndConditions" />
                    </div>

            </div>

            <div class="w-100 d-flex flex-row justify-content-stretch p-0 button-group">
                <button type="button"
                    class="p-3 fpink border-0 flex-grow-1 font-weight-bold"
                    @click="showTerms = false"
                >
                    CANCEL
                </button>
                <button type="button"
                    class="p-3 fblue border-0 flex-grow-1 font-weight-bold"
                    data-dismiss="modal"
                    @click="fdata.agree = true;showTerms = false;"
                >
                    AGREE
                </button>
            </div>
          </div>


            </Modal>




  </div>
</div>


<transition name='drawer'>
    <div
    v-if="showDrawer||showNavMenu"
      class="d-flex flex-column align-items-start
        position-fixed my-overlay   my-drawer vw-100 vh-100
       "
       id="nav-menu"
    >
        <div
            class="bg-white p-4 h-100
            d-flex flex-column align-items-start  "
            v-click-outside="closeDrawer"
            style="box-shadow: 0 0 8px black;"

        >

            <div>
                <h5 class="logo-font font-weight-bold text-pri border-bottom pb-2"
                    @click="goToRoute('home')"
                >
                    ProtectMeWell™
                </h5>
            </div>

            <button class=" bg-transparent logo-font desktop-nav-button my-1 d-block"
                v-for="b in navButtons" :key="b.name"
                :class="($route.name == b.name)?'font-weight-bold border-bottom border-dark  text-pri'
                :' border-0 text-gray '"
                @click="goToRoute(b.name);"
            >
                {{routeName(b)}}
            </button>

             <product-dropdown  v-on="$listeners" @getStarted="goToRoute('form')"
                @showGiftCardPopUp="resetForm();showGiftCardPopUp = true"
                :hover="false" :mobileMode="mobileMode"
            />


            <div class="text-left mt-5">
            <button class="border-0  font-weight-300
                     shadow-sm
                text-uppercase p-2 rounded special-font px-2"
                :class="'text-white bg-pri'"
            @click="goToRoute('form')"


                >
                Get Started
            </button>
        <button v-if="!$store.state.user &&  $route.path.indexOf('credit') <= -1"
            class=" bg-transparent special-font  ml-1 ml-2 p-1 px-3"
            :class="'font-weight-500  rounded   '+
            (inverted?'text-white w-border ':'text-pri p-border')"
            @click="openSignIn"
        >
        SIGN IN
        </button>
         <button v-else-if="$route.path.indexOf('credit') <= -1"
            class=" bg-transparent special-font  ml-1 ml-2 p-1 px-3"
            :class="'font-weight-500  rounded   '+
            (inverted?'text-white w-border ':'text-pri p-border')"
            @click="signOut"
        >
        LOG OUT
        </button>
        </div>
        </div>
    </div>
</transition>

    <Modal
        :show="showGiftCardPopUp"
    >
        <div class=" position-relative d-flex flex-column rounded-extra gift-sec"
                    style="min-width:17em;"
        >
            <button class="position-absolute fas fa-times
            text-white small p-3 ml-auto border-0 bg-transparent"
                style="right:0;top:0;"
                @click="showGiftCardPopUp = false"
            >

            </button>

            <div class="bg-pri d-flex justify-content-between px-4 p-3" style="color: white;">
                <p class="font-weight-bold m-0">
                    ProtectMeWell Gift Card
                </p>
            </div>
            <div class="p-3 mt-4 mb-4">
                <div>
                    <v-input
                        type="email"
                        label="Email ID"
                        v-model="giftCardData.emailId"
                        class="form-text-input text-left"
                    />
                    <p class="text-muted m-0 text-right" style="font-size:10px;color: #9B9B9B;">invoice and gift cards link will shared by mail</p>
                </div>
                <div class="mt-4">
                    <v-input type="text"
                        label="Gift to"
                        v-model="giftCardData.name"
                        class="form-text-input text-left"
                    />
                    <p class="text-muted m-0 text-right" style="font-size:10px;color: #9B9B9B;">this will be added on the card</p>
                </div>
                <div class="mt-4 d-flex justify-content-between">
                    <p class="text-left" style="font-family: Roboto;font-size: 11px;line-height: 19px;color: #9B9B9B;">Number of credits to buy</p>
                    <div class="d-flex justify-content-between" style="border: 1px solid #9B9B9B;padding-left: 10px;padding-right: 10px;width: 95px;height: 20px;color: #9B9B9B;font-size: 12px;">
                        <p class="cursor-pointer pr-2" @click="giftCardData.credits -= 1;calculateGiftAmt();" style="border-right:1px solid #9B9B9B;height: 100%;"> - </p>
                        <p>{{ giftCardData.credits }}</p>
                        <p class="cursor-pointer pl-2" @click="giftCardData.credits += 1;calculateGiftAmt();" style="border-left:1px solid #9B9B9B;height: 100%;"> + </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                    <p class="cursor-pointer cr-vector" @click="giftCardData.credits += 20;calculateGiftAmt();">+20</p>
                    <p class="cursor-pointer cr-vector" @click="giftCardData.credits += 100;calculateGiftAmt();">+100</p>
                    <p class="cursor-pointer cr-vector" @click="giftCardData.credits += 200;calculateGiftAmt();">+200</p>
                    <p class="cursor-pointer cr-vector" @click="giftCardData.credits += 1000;calculateGiftAmt();">+1000</p>
                </div>
            </div>
            <div class="d-flex mt-auto">
                <button class="text-pri bg-red logo-font
                    border-0 font-weight-bold text-right p-3 pr-5"
                    @click="showGiftCardPopUp = false"
                    >
                    BACK
                </button>
                <button class="text-pri bg-green logo-font flex-grow-1
                    border-0 font-weight-bold text-right p-3 pr-5" @click="validateAndPayForGiftCard(giftCardData.emailId, giftCardData.name)">
                    PAY {{ final_price }}
                </button>
            </div>
        </div>

    </Modal>
    <Modal
        :show="$store.state.gift_card_unlocked"
    >

        <div class=" position-relative d-flex flex-column rounded-extra gift-sec"
                    style="width:20em;"
        >
            <div class="p-2 mt-2 mb-4">
                <div class="mb-4 position-relative">
                    <div id="creditCard" ref="creditCard">
                        <img  v-if="giftCardData.credits <=10" src="/images/basic.svg" style="width: 100%;object-fit: scale-down;">
                        <img  v-else-if="giftCardData.credits >=11 && giftCardData.credits <=25" src="/images/executive.svg" style="width: 100%;object-fit: scale-down;">
                        <img  v-else src="/images/premium.svg"  style="width: 100%;object-fit: scale-down;">
                        <div style="position: absolute;bottom: 50px;">
                            <div class="card-label">
                                <span>VALID THRU</span>
                                <span style="margin-left:50px;">CREDITS</span>
                            </div>
                            <div class="card-det" style="position: relative;bottom: 12px;">
                                <span style="float:left;text-transform:uppercase;">{{moment(giftCardData.valid_till, 'DDMMMYY')}}</span>
                                <span style="margin-left:35px;">{{giftCardData.credits}}</span>
                            </div>
                        </div>
                        <div style="position: absolute;right: 14px;bottom: 12px;">
                            <img v-if="$store.state.giftCardQRLink" width="50px" :src="$store.state.giftCardQRLink">
                        </div>
                        <div style="position: absolute;bottom: 5px;">
                            <div class="card-label">
                                <span>CREDITS GIFTED TO</span>
                            </div>
                            <div class="card-det" style="position: relative;bottom: 12px;text-transform: uppercase;">
                                <span style="float:left;margin-left:2px;">{{giftCardData.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <p class="text-center" style="color: #3D5F9F;font-family: Roboto;font-size: 20px;">GIFT CARD EMAILED</p>
                </div>
                <div class="gift-card-details mt-4">
                    <p style="text-align:end;">Credits in Card:</p>
                    <p style="margin-left: 20px;text-align:start;">{{giftCardData.credits}} Credits</p>
                </div>
                 <div class="gift-card-details">
                    <p style="text-align:end;">Valid Till:</p>
                    <p style="margin-left: 20px;text-align:start;text-transform:uppercase;">{{moment(giftCardData.valid_till, 'DD MMM YYYY')}}</p>
                </div>
                 <div class="gift-card-details">
                    <p style="text-align:end;">Cart Value:</p>
                    <p style="margin-left: 20px;text-align:start;">{{giftCardData.amt_details.cart_value}}</p>
                </div>
                 <div class="gift-card-details">
                    <p style="text-align:end;">Discount:</p>
                    <p style="margin-left: 20px;text-align:start;">{{giftCardData.amt_details.total_disc }} ({{giftCardData.amt_details.coupon_code }})</p>
                </div>
                 <div class="gift-card-details">
                    <p style="font-size: 20px;text-align:end">Amount Paid:</p>
                    <p style="margin-left: 20px;font-size: 20px;font-weight: 500;text-align:start;">Rs. {{final_price}}</p>
                </div>
            </div>
            <div class="d-flex mt-auto">
                <button class="text-pri bg-red logo-font
                    border-0 font-weight-bold text-right p-3 pr-5"
                    @click="showGiftCardPopUp = false;$store.commit('setGiftCardUnlocked',false)"
                    >
                    CLOSE
                </button>
                <button class="text-pri bg-green logo-font flex-grow-1
                    border-0 font-weight-bold text-right p-3 pr-5"
                    v-clipboard="$store.state.link_url"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError">
                    COPY CARD LINK
                </button>
            </div>
        </div>

    </Modal>
</div>
</template>

<script>
import Modal from '../common/Modal'
import VInput from '../input/VInput'
import ClickOutside from 'vue-click-outside'
import {ValidationObserver} from 'vee-validate'
import texts from '../../texts'
import ProductDropdown from './ProductDropdown.vue'
import moment from 'moment'

const config = {
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'X-API-KEY':process.env.VUE_APP_API_KEY
    }
}
export default {
    texts,
    components:{
        Modal,
        VInput,
        ValidationObserver,
        ProductDropdown,
    },
    props:{
        showNavMenu:{default:false},
        scrolly:{default:0},
        mobileMode:{
            type:Boolean,
        }
    },
    directives:{ClickOutside},
    data(){

        const notification = this.$notification

        return {
            navButtons:[/*'home',*/'about','how-it-works','affiliate-marketing','faq'].map(nb => {return{name:nb,}}),
            screenWidth:800,
            sendingOTP:false,
            sentOTP: false,
            loading:false,
            siteKey:'6Lf58r0ZAAAAAIty4bouhBatp2KEUprV977R03f2',
            capToken:'',
            showCaptcha: false,
            formKey:1,
            showCreditScreen:false,
            selectedPlan: '',
            showShareCreditScreen: false,
            shareCreditErr: false,
            showCreditLinkScreen: false,
            showTerms: false,
            texts,
            fdata:{
                name:'',
                email:'',
                emailOrPhone:'',
                mobile:'',
                password:'',
                otp:'',
            },
            notification,
            plans:[
                { id: 'AX13015', name:"bronze",color:'#e79543',price:499,special_price:99,credits:20, coupon_code: 'IFAB80'},
                { id: 'AX13016', name:"silver",color:'#e5e5e5',price:999,special_price:199,credits:50, coupon_code: 'IFAS80'},
                { id: 'AX13017', name:"gold",color:'#fdd551',price:1499,special_price:299,credits:100, coupon_code: 'IFAG80'},
            ],
            shareData: {
                emailOrMob : '',
                sCredits: ''
            },
            creditLinkData: {
                link_No: '',
                link_Credits: '',
                isCreditsAvailable: false,
                generatedCreditLink: ''
            },
            showGiftCardPopUp: false,
            giftCardData: {
                emailId: '',
                name: '',
                credits: 0,
                card_img: '',
                valid_till: new Date(),
                amt_details: {}
            },
            showGiftCardSuccessPopUp: false  ,
            final_price: 0,
        }
    },
    mounted(){
        this.$store.dispatch('fetchUser')
        let str = window.location.href.split('credit/')[1];
        if (str) {
            let params = str.split('/');
            if (window.location.href.indexOf('credit') > -1) {
                this.$store.dispatch('getCreditLinkByUrl', {creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard });
            }
        }
        this.displayCaptcha()
        const handleResize = () => {
            this.screenWidth = document.documentElement.clientWidth
        }
        window.addEventListener('resize',handleResize)
        handleResize()
        this.$root.$on('onRegister', () => {
            this.$store.commit('setShowSignIn',2)
            this.showDrawer=false;
        })
    },
    methods:{
        onCopy: function () {
                // alert('You just copied the following text to the clipboard: ' + e.text)
            this.$success('Link copied successfully !!')
        },
        onError: function (e) {
            //alert('Failed to copy the text to the clipboard')
            console.log(e);
            this.$error('Failed to copy link !! Please try again !!')
        },
        moment: function (date, format) {
            return moment(date).format(format);
        },
        async getCreditLinkDetails(creditId, creditUrl){
            try{
                const url = this.$base_Node_Url +'credit-link/getCredit/'+creditId+'/'+creditUrl
                const resp = await this.$axios.get(url);
                if (resp && resp.data && resp.data.link_url.length > 0) {
                    this.creditLinkCredits = resp.data.link_url[0].credits;
                }
            }catch(err){
               return this.notification.error({message:'Unable to get credit link details !!'})
            }
        },
        hideSignIn(){
            this.showSignIn=false
            this.formKey ++;
        },
        resetForm(){
            this.interation += 1;
            this.showCaptcha = false;
            this.sentOTP = false;
            this.capToken = ''

            for(let k in this.fdata){
                this.fdata[k] = ''
            }
            if (this.shareData) {
                for(let k in this.shareData){
                    this.shareData[k] = ''
                }
            }
            if (this.creditLinkData) {
                for(let k in this.creditLinkData){
                    this.creditLinkData[k] = ''
                }
            }
            if (this.giftCardData) {
                for(let k in this.giftCardData){
                    this.giftCardData[k] = ''
                }
                this.giftCardData['credits'] = 0;
                this.giftCardData['valid_till'] = new Date();
            }
            this.final_price = 0;
            this.$store.commit('unlockGiftCard', false);
        },
        onCaptcha(val){
            this.capToken = val
        },
        signOut(){
            this.$store.commit('logout')
            this.showDrawer=false;
            this.$store.commit('setPlanData',null)
            this.$router.push({name:'home'})
        },
        handlePhoneInput(val){
            if(val.length == 10){
                this.sendOTP()
            }
        },
        handleNext(){
            if(!this.sentOTP){
                if (this.showSignIn === 2) {
                      return this.sendOTP();
                } else {
                     return this.resendOTP()
                }
            }else{
                if(this.showCaptcha && !this.capToken){
                    // this.$error('Please complete captcha to continue')
                    this.authenticate()
                }else{
                    this.authenticate()
                }
            }
        },
        displayCaptcha() {

          setTimeout(() => {
              if(window.grecaptcha&&window.grecaptcha.render){
                  window.grecaptcha.render('g-recaptcha', {
                          'sitekey' : this.siteKey,
                          'callback':this.onCaptcha
                  });
              }else{
                  this.displayCaptcha();
              }
          }, 200);

        },
        resetCaptcha(){
            if(window.grecaptcha){
                window.grecaptcha.reset();
                this.showCaptcha = false;
            }
        },
        async sendOTP(){
            if(this.showCaptcha){
                this.displayCaptcha()
            }
            if (!this.fdata.agree){
                // alert('you must accept the terms and conditions')
                this.notification.error({message:'Please agree to Terms and Conditions'});
                return;
            }
            if(this.sendingOTP){return}
            const url = this.$base_Node_Url +'ifaUser/generateAndSendOTP'
            const data = {mobile:this.mobile,mode:'M'}
            const fdata = this.fdata
            if(this.loginTab == 1){
                const inp = this.fdata.emailOrPhone
                if(inp.includes('@')){
                    data.mode = 'E'
                    data.email = inp
                }else{
                    data.mobile = inp
                }
            }
            else if(this.loginTab == 2){
                data.name = fdata.name
                data.email = fdata.email
                data.mobile = fdata.mobile
                data.mode = 'B'
            }

            try {
                this.loading = true
                this.sendingOTP = this.loginTab
                await this.$wait(2000)

                const res = await this.$axios.post(url,data,config)
                this.sentOTP |= this.loginTab
                this.showCaptcha = true
                this.$success('OTP is sent to your email '||res)
            } catch (err) {
                this.$error(err)
            }finally{
                this.sendingOTP = 0;
                this.loading = false
            }
        },
        async resendOTP(){
             if(!(this.fdata.emailOrPhone)){return}
            if(this.sendingOTP){return}
            const url = this.$base_Node_Url +'ifaUser/resendOTP'
            const data = {mobile:this.mobile,mode:'M'}
            const fdata = this.fdata
            if(this.loginTab == 1){
                const inp = this.fdata.emailOrPhone
                if(inp.includes('@')){
                    data.mode = 'E'
                    data.email = inp
                }else{
                    data.mobile = inp
                }
            }
            else if(this.loginTab == 2){
                data.name = fdata.name
                data.email = fdata.email
                data.mobile = fdata.mobile
                data.mode = 'B'
            }

            try {
                this.loading = true
                this.sendingOTP = this.loginTab
                await this.$wait(2000)

                const res = await this.$axios.post(url,data,config)
                this.sentOTP |= this.loginTab
                this.showCaptcha = true
                this.$success('OTP is sent to your email'||res)
            } catch (err) {
                this.$error(err)
            }finally{
                this.sendingOTP = 0;
                this.loading = false
            }
        },
        async showTheTerms(){
            await this.$wait(200)
            this.showTerms = true;
        },
        async authenticate(){
            const url = this.$base_Node_Url +'ifaUser/verifyOTPLogin'
            const fdata = this.fdata
            const data = {}
            const inp = this.fdata.emailOrPhone.toLowerCase().trim()
            if(this.loginTab == 1){
                if(inp.includes('@')){
                    data.mode = 'E'
                    data.email = inp
                    data.email_otp = fdata.otp
                }else{
                    data.mode = 'M'
                    data.mobile = inp
                    data.mobile_otp = fdata.otp
                }
            }else{
                data.email_otp = data.mobile_otp = fdata.otp
                data.email = fdata.email
                // data.moile = fdata.mobile
                data.mode = 'E'
            }
            try {
                this.loading = true
                await this.$wait(2000)
                const res = await this.$axios.post(url,data,config)
                this.$success(res)
                this.showSignIn = false
                this.$store.commit('set_client_id',res.data.data.client_id);
                this.$store.commit('set_session_id',res.data.data.session_id)
                this.$store.commit('login',res.data)
                this.$store.commit('setRefreshGetStarted',true);
                this.resetForm()
                this.resetCaptcha()
                this.$store.commit('setPlanData',null)
                // this.$router.push({name:'home'})
                window.location.reload();
            } catch (err) {
                this.$error(err)
            }
            finally{
                this.loading = false
            }

        },
        validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
        },
        calculateGiftAmt() {
            this.final_price = 0;
            let base_price = 99, base_disc_price = 70, disc;
            this.giftCardData.valid_till = new Date();
            if (this.giftCardData.credits <= 10) {
                this.giftCardData.valid_till =  (this.giftCardData.valid_till).setDate((this.giftCardData.valid_till).getDate() + 1);// 1 day validity
                disc = 0.1;
            } else if (this.giftCardData.credits >= 11 && this.giftCardData.credits <= 25) {
                this.giftCardData.valid_till =  (this.giftCardData.valid_till).setDate((this.giftCardData.valid_till).getDate() + 14);// 14 days validity
                disc = 0.2;
            } else {
                this.giftCardData.valid_till =  (this.giftCardData.valid_till).setDate((this.giftCardData.valid_till).getDate() + 28);// 28 days validity
                disc = 0.3;
            }
            let total_base_price = base_price * this.giftCardData.credits;
            let total_base_disc_price = base_disc_price * this.giftCardData.credits;
            let bulk_disc = (total_base_price - total_base_disc_price) * disc;
            let total_disc = bulk_disc + total_base_disc_price;
            this.final_price = total_base_price - total_base_disc_price - bulk_disc;
            this.giftCardData['amt_details'] = {
                    credits: this.giftCardData.credits,
                    coupon_code: 'GIFTCARD',
                    base_price: base_price,
                    base_disc_price: base_disc_price,
                    disc: disc,
                    total_base_disc_price: total_base_disc_price,
                    bulk_disc: bulk_disc,
                    total_disc: total_disc,
                    cart_value: total_base_price,
                    final_price: this.final_price
            }
            this.giftCardData['creditCardImgRef'] = this.$refs.creditCard;
            this.giftCardData['creditCardImgDiv'] = document.getElementById('creditCard');
            this.giftCardData['expiry_date'] = moment(this.giftCardData.valid_till).format('DDMMMYY');
        },
        async validateAndPayForGiftCard(emailId, name) {
            this.giftCardData['name'] = name;
            this.giftCardData['email'] = emailId;
            this.giftCardData['recipient_name'] = name;
            if (!emailId || !name) {
                 return this.$error('Please fill in the required fields !!');
            }
            if (!this.giftCardData.credits || this.giftCardData.credits <= 0) {
                return this.$error('Please enter valid credits !!');
            }
            if (!this.validEmail(emailId)) {
                return this.$error('Please enter valid email !!');
            }
            this.loading = true;
            this.$store.dispatch('showGiftCardPayment', this.giftCardData, emailId);
            if (this.$store.state.gift_card_unlocked) {
                this.showGiftCardSuccessPopUp = true;
                this.showGiftCardPopUp = false;
            }
             this.loading = false;
        },
        checkShareCredits() {
            this.shareCreditErr = false;
            if (!this.shareData.sCredits || this.shareData.sCredits <= 0) {
                this.shareCreditErr = true ;
                return this.$error('Please enter valid credits');
            }
            if (this.shareData.sCredits >= this.$store.state.user.credits) {
                this.shareCreditErr = true;
                return this.$error('Max limit exceeded: Only ' +(this.$store.state.user.credits-5) + ' can be shared')
            } else {
                if ((this.$store.state.user.credits - this.shareData.sCredits) <= 5)  {
                    this.shareCreditErr = true;
                    return this.$error('You dont have enough credits to share')
                }
            }

        },
        async shareCredits() {
            if(!(this.shareData.sharedEmailOrMob || this.shareData.sCredits)) { return }
            if (this.$store.state.user.credits <= 0) {
                return this.$error('Insufficient Credits !! Please add credits');
            }
            this.checkShareCredits();
            if (this.shareCreditErr) { return }
            const url = this.$base_Node_Url +'ifaUser/checkEmailOrMobForCreditShare';
            try {
                this.loading = true
                const inp = this.shareData.sharedEmailOrMob;
                let data = {}
                if(inp.includes('@')){
                    data.mode = 'E'
                    data.email = inp
                }else{
                    data.mode = 'M'
                    data.mobile = inp
                }
                await this.$wait(2000)
                const res = await this.$axios.post(url,data,config)
                if (res) {
                    data = {};
                    if(inp.includes('@')){
                        data.mode = 'E'
                        data.toEmail = inp
                    }else{
                        data.mode = 'M'
                        data.toMobile = inp
                    }
                    data.credits = this.shareData.sCredits;
                    const url1 = this.$base_Node_Url +'ifaCreditXfer/shareCreditToSelUser';
                    this.loading = true
                    await this.$wait(2000)
                    const res = await this.$axios.post(url1,data,config)
                    this.$success(res)
                    this.showSignIn = false
                    this.resetForm()
                    this.resetCaptcha()
                    this.showCreditScreen = false;
                    this.showShareCreditScreen = false;
                    this.$store.dispatch('fetchUser')
                }
            } catch (err) {
                this.$error(err)
            }
            finally{
                this.loading = false
            }

        },
        async creditLinkGenerate() {
            if(!(this.creditLinkData.link_No || this.creditLinkData.link_Credits)) { return }
            if (this.$store.state.user.credits <= 0) {
                return this.$error('Insufficient Credits !! Please add credits');
            }
            if (!this.creditLinkData.isCreditsAvailable) { return }
            const url = this.$base_Node_Url +'credit-link/createCreditLinksAndShare';
            try {
                this.loading = true
                await this.$wait(2000)
                const res = await this.$axios.post(url,{link_No: this.creditLinkData.link_No, link_Credits: this.creditLinkData.link_Credits, user_id: this.$store.state.user._id},config)
                if (res) {
                    this.$success(res.data.message)
                    this.showSignIn = false;
                    if (res.data.data) {
                        if (this.creditLinkData.link_No === 1) {
                            this.creditLinkData.generatedCreditLink = res.data.data[0].url
                        }
                    }else {
                        if (this.creditLinkData.link_No > 1) {
                            this.showCreditLinkScreen = false;
                            this.creditLinkData.generatedCreditLink = '';
                        }
                    }

                    this.$store.dispatch('fetchUser');
                }
            } catch (err) {
                this.$error(err)
            }
            finally{
                this.loading = false
            }

        },
        async openSignIn(){
            await this.$wait(20)
            this.resetForm();
            this.showDrawer=false;
            this.showSignIn = true;
        },
        goToRoute(name){
            if(this.$route.name != name){
                this.$router.push({name});
            } else {
                window.location.reload();
            }
            this.showDrawer=false;
            this.$emit('close');
        },
        routeName(b){

            return (b.name.toUpperCase()+(b.name=='faq'?'s':'')).replace(/-/g,' ');
        },
        async closeDrawer(){
            if(this.showDrawer){
                this.$emit('close')
            }
        },
        async displayDrawer(){

            setTimeout(() => {
                this.showDrawer=true;
            },50);
        },
        addCredit() {
            if (!this.selectedPlan) {
                return this.$error('Please select a plan')
            }
            this.$store.dispatch('addCreditPayment', this.selectedPlan)
        }

    },
    computed:{
        showDrawer:{
            get(){
                return this.showNavMenu
            },
            set(val){
                if(val){
                    this.$emit('open')
                }else{
                    this.$emit('close')
                }
            }
        },
        loginTab(){
            return this.showSignIn
        },
        showSignIn:{
            get(){
                return this.$store.state.showSignIn
            },
            set(val){
                this.$store.commit('setShowSignIn',val)
            }
        },
        user(){
            return this.$store.state.user;
        },
        shadowClass(){
            return this.scrolly != 0 ? ' shadow-md ':''
        },
        isHome(){
            return this.$route.name == 'home'
        },
        isFaq(){
            return ['how-it-works','blog','faq'].includes(this.$route.name)
        },
        isAfm(){
            return ['how-it-works','blog','affiliate-marketing','faq'].includes(this.$route.name)
        },
        inverted(){
            return ['about-us'].includes(this.$route.name) ;
        },
        transparent(){
            return this.$route.name != 'form' && !this.isHome
        },
        isForm(){
            return this.$route.name == 'form'
        },
        isHowItWorks(){
            return this.$route.name == 'how-it-works'
        },
        showBg(){
            let fixedBg = ['blog','home'].includes(this.$route.name) && this.scrolly > 0
            // good = good ||  (this.isForm && !this.mobileMode && this.scrolly>0)
            // good = good || (this.screenWidth < 576)
            // good = good || (this.isHome &&  this.scrolly>0)
            let placeBg = ['how-it-works',].includes(this.$route.name)

            placeBg = placeBg || (this.isForm && !this.mobileView && this.screenWidth < 576)

            if(fixedBg){
                return 'fixed-bg'
            }
            else if (placeBg){
                return  'placed-bg'
            }

            return ' z-2 '

        },
        checkShowCreditPopup () {
            return this.$store.state.showCreditScreen
            // Or return basket.getters.fruitsCount
            // (depends on your design decisions).
        },
        checkCredits () {
            if (this.$store.state.user && this.$store.state.user.credits) {
                return this.$store.state.user.credits
          }else{
                return -1
          }
            // Or return basket.getters.fruitsCount
            // (depends on your design decisions).
        },
        checkGiftCardPopup() {
            return this.$store.state.showGiftCardPopup
        }
    },
    watch: {
        checkShowCreditPopup (newVal, oldVal){
            console.log(newVal, oldVal)
            this.showCreditScreen = newVal;
        },
        'creditLinkData.link_Credits': function() {
            if ((this.creditLinkData.link_No * this.creditLinkData.link_Credits) <= this.$store.state.user.credits) {
                this.creditLinkData.isCreditsAvailable = true;
            } else {
                this.creditLinkData.isCreditsAvailable = false;
            }
        },
        checkGiftCardPopup(newVal) {
            this.showGiftCardPopUp = newVal;
        }

    }
}
</script>

<style scoped>

.my-navbar.inverted{
    background-color: transparent;
    color: var(--blue);
}
.my-navbar.inverted > * {

    color:var(--blue);
}
.my-navbar.binverted  a{
    color:var(--darkblue);
}
/* .my-navbar.whitened a {
    color: white;
} */

 .dk-transparent{
     background-color: transparent;
 }
 .dk-fixed{
     position: fixed;
 }
    .always-fixed{
        position: fixed;
        width: 100%;
    }
    @media screen and (max-width: 575px) {
        .desktop-specific{
            display:none;
        }
        .dk-transparent{
            background-color: var(--darkblue);
        }
        .dk-fixed{
            position: relative;
        }
         /* Fixed nav-bar for all devices */
.auto-fixed{
            position: relative;
            width: 100%;
            /* background-color: rgba(0, 0, 0, 0.1); */
        }
    }

    .desktop-nav-button{
        border:none;
        outline:none;
    }
    .desktop-nav-button:focus{
        outline:none;
    }

    .ham-icon-light,.ham-icon-dark{
        width: 24px;

    }

    .ham-icon-dark{

        /* filter: brightness(45%) sepia(100) saturate(6) hue-rotate(145deg); */
        filter: brightness(18%) sepia(100) saturate(6) hue-rotate(200deg);
    }

    .border-white{
        border-bottom: 2px solid white;;
    }

    .logo{
        height: 60px;
        color: blue;
    }
    @media screen and (max-width: 575px) {
        .logo {
            height: 40px;
        }
    }
    .logo-form{
        filter: brightness(70%) sepia(100) saturate(50) hue-rotate(165deg);
    }


    .bordered{
        border-bottom: 3px solid;
    }
    .my-drawer{
        left:0;
        top:0;
    }

    .drawer-enter-active, .drawer-leave-active {
        transition: all .6s;

    }

    .drawer-enter, .drawer-leave-to /* .fade-leave-active below version 2.1.8 */ {
        background-color: transparent;

    }
    .drawer-enter-active > *, .drawer-leave-active > * {
        transition: all .6s;

    }

    .drawer-enter > *, .drawer-leave-to > * /* .fade-leave-active below version 2.1.8 */ {
        transform: translateX(-100%);

    }
    .shadow-md{
        box-shadow:  0 0 8px darkgray;
    }
    #navigation-header{
        transition: background-color 0.6s;
    }
   @media screen and (orientation: portrait){
   }
    #navigation-header.fixed-bg{
        /* background-color: var(--pri); */
        background-color: white;
        /* border-bottom: 2px solid rgba(117, 126, 134, 0.384); */
        box-shadow: 0 0 4px lightblue;
        /* position:relative; */
        z-index: 2;
    }

    .z-2{
        z-index: 2;
    }

    #navigation-header.placed-bg{
        /* background-color: var(--pri); */
        background-color: white;
        /* border-bottom: 2px solid rgba(117, 126, 134, 0.384); */
        box-shadow: 0 0 4px lightblue;
        position:relative;
        z-index: 2;

    }

    .w-border{
        border:2px solid white;
    }
    .p-border{
        border:2px solid var(--darkerblue);
    }

    .login-tab{
        color: white;
        background-color: transparent;
        border: none;
        font-weight: bold;
    }
    .login-tab.active{

        border-bottom: 3px solid white;
        font-weight: bold;
    }

    @media screen and (max-width: 575px) {
        .btn-mb {
            font-size: 10px;
            position: relative;
            /* top:20px; */
            padding: 5px;
        }
        .shield-btn {
            top:15px;
        }
    }

    #nav-menu{
        z-index: 10002;
    }
    .gift-sec .cr-vector{
        background: #ccc;
        color:white;
        border-radius: 15px;
        font-size: 13px;
        width: 37px;
        height: 22px;
        padding: 1px;
    }
    .gift-card-details {
        display: flex;
        justify-content: center;
    }
    .gift-card-details p{
        color: #828282;
        font-family: Roboto;
        font-size: 14px;
        line-height: 20px;
        margin-bottom:10px;
        flex: 1;
    }
    .gift-sec .card-label{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: white;
        left: 0;
        right: 0;
        padding: 15px;
        font-family: Roboto;
        font-weight: bold;
        font-size: 6px;
        line-height: 9px;
        letter-spacing: 0.1em;
        color: #9B9B9B;
    }
    .card-det{
        font-family: Credit Card;
        font-size: 13px;
        line-height: 10px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
        margin: 0 15px;
        font-weight: bold;
    }
</style>