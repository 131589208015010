import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import FormPage from '../views/FormPage.vue'
import VueAnalytics from 'vue-analytics'
import page404 from '../views/PageNotFound'
// import Form from '../views/Form.vue'

Vue.use(VueRouter)

const routeNames = [ 'form','about','how-it-works','affiliate-marketing','faq','blog']
function caps(name) {
  name = name.replace(/-/g,'');
  return (name.slice(0,1).toUpperCase()+name.slice(1,name.length));
}
const routeArray = routeNames.map(rn =>
  {return{
    path:'/'+rn,name:rn,
    component: ()=>import('../views/'+caps(rn)+'.vue')}
  })


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/checklist',
    name: 'home',
    component: Home,
  },
  {
    path: '/credit/:creditId/:urlLink' ,
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue')
  },
  {
    path: '/PMWGiftCard/credit/:creditId/:urlLink' ,
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue')
  },
  {
    path: '/giftCard' ,
    name: 'giftcard',
    component: Home
  },
  ...routeArray
  ,
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path:'*',
    name:'404',
    component: page404
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

Vue.use(
  VueAnalytics,{
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    router
  }
);


export default router
